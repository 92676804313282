<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Import Data</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.period"
                  label="Period"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="month"
                    placeholder="Enter period"
                    autocomplete="off"
                    v-model="form.period"
                  ></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.file"
                  label="File"
                  :use-horizontal="false"
                >
                  <b-form-file
                    placeholder="Choose file"
                    accept=".xls,.xlsx,.csv"
                    v-model="form.file"
                    @input="selectFile"
                  ></b-form-file>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm">Back</b-button>
              <b-button
                type="submit"
                size="sm"
                variant="success"
                class="float-right"
              >
                Import
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    route: {
      form: "MeterReadingsImport",
      table: "MeterReadings",
    },
    form: {
      period: null,
      file: null,
      fileName: null,
      fileContent: null,
    },
  }),
  validations: {
    form: {
      period: { required },
      file: { required },
    },
  },
  methods: {
    selectFile() {
      const self = this;
      self.form.fileName = self.form.file.name;

      let reader = new FileReader();
      reader.addEventListener("load", (readerLoadEvent) => {
        self.form.fileContent = Array.from(
          new Uint8Array(readerLoadEvent.target.result)
        );
      });
      reader.readAsArrayBuffer(self.form.file);
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText = "You are about to import these data. Are you sure ?",
        _okText = "Yes, Import",
        _action = "apis/post",
        _url = "/meterreading";

      let _form = {
        period: self.form.period,
        fileName: self.form.fileName,
        fileContent: self.form.fileContent,
      };

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Cancel",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch(_action, {
              url: _url,
              params: _form,
            })
            .then((response) => {
              dialog.close();
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.detail || response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.$router.go(-1);
              }
            });
        });
    },
  },
};
</script>